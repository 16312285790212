




















































import { Component, PropSync, Vue } from 'vue-property-decorator';
import ModifySwsModerateWealthExitPollViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/modify-sws-moderate/modify-sws-moderate-wealth-exit-poll-view-model';

@Component({ name: 'ModifySwsModerateWealthExitPoll' })
export default class ModifySwsModerateWealthExitPoll extends Vue {
  @PropSync('investorGoalId', { type: String, required: false })
  synced_investor_goal_id!: string;

  modify_moderate_wealth_exit_poll_view_model = Vue.observable(
    new ModifySwsModerateWealthExitPollViewModel(this),
  );

  created() {
    this.modify_moderate_wealth_exit_poll_view_model.initialized(this.synced_investor_goal_id);
  }
}
