import TYPES from '@/types';

import { v4 } from 'uuid';
import Vue from 'vue';

// Application
import SearchAnswersExitPollQuery
  from '@/modules/flagship/contact-points/sowos-moderate/application/queries/search-answers-exit-poll-query';
import GetInvestmentProductFundTypeQuery
  from '@/modules/flagship/catalogs/investment-product-fund-type/application/queries/get-investment-product-fund-type-query';
import CreateResponseUnlinkGoalFromModerateCommand
  from '@/modules/flagship/contact-points/sowos-moderate/application/commands/create-response-unlink-goal-from-moderate-command';

// Domain
import { PollResultEntity }
  from '@/modules/flagship/contact-points/sowos-moderate/domain/entities/poll-result-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ModifySwsModerateWealthExitPollViewModel {
  @Inject(TYPES.SEARCH_ANSWERS_EXIT_POLL_QUERY)
  readonly get_answers_exit_poll!: SearchAnswersExitPollQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY)
  readonly get_investment_product_fund_type!: GetInvestmentProductFundTypeQuery;

  @Inject(TYPES.CREATE_RESPONSE_UNLINK_GOAL_FROM_MODERATE_COMMAND)
  // eslint-disable-next-line max-len
  readonly create_response_unlink_goal_from_moderate!: CreateResponseUnlinkGoalFromModerateCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.modify-sws-moderate.modify-sws-moderate-wealth-exit-poll';

  readonly view: Vue;

  is_loading = false;

  survey_selected: PollResultEntity = {
    id: '',
    poll_status_id: '',
    label: '',
    description: '',
    name: '',
  };

  id = v4();

  investment_product_fund_type_id = '';

  investor_goal_id = '';

  answers: Array<PollResultEntity> = [];

  constructor(view: Vue) {
    this.view = view;
  }

  get send_survey_disabled() {
    return !this.survey_selected.id || !this.investment_product_fund_type_id || this.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  sendSurvey = async (): Promise<void> => {
    this.is_loading = true;
    try {
      const response_survey = {
        id: this.id,
        investment_product_fund_type_id: this.investment_product_fund_type_id,
        investor_goal_id: this.investor_goal_id,
        poll_status_id: this.survey_selected.poll_status_id,
        poll_result_id: this.survey_selected.id,
      };

      await this.create_response_unlink_goal_from_moderate.execute(response_survey);
      this.message_notifier.showSuccessNotification(this.translate('messages.create_response_survey'));
      this.view.$emit('endProcess');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_response_survey'));
    } finally {
      this.is_loading = false;
    }
  }

  loadAnswers = async () => {
    try {
      this.answers = await this.get_answers_exit_poll.execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_answers'));
    }
  }

  loadInvestmentProductFundTypes = async () => {
    try {
      const investment_product_fund_types = await this.get_investment_product_fund_type.execute();
      const moderate_investment_product_fund_type = investment_product_fund_types.find(
        (fund_type) => fund_type.name === 'SWS-MODERADO',
      );
      if (moderate_investment_product_fund_type) {
        this.investment_product_fund_type_id = moderate_investment_product_fund_type.id;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_answers'));
    }
  }

  initialized = async (investor_goal_id: string) => {
    this.is_loading = true;
    this.investor_goal_id = investor_goal_id;
    await this.loadAnswers();
    await this.loadInvestmentProductFundTypes();
    this.is_loading = false;
  }
}
